var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{ref:"openModalPin",staticClass:"d-none",attrs:{"data-toggle":"modal","data-target":"#pinModalDirect"}}),_c('div',{staticClass:"modal fade",attrs:{"id":"pinModalDirect","tabindex":"-1","role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h6',{staticClass:"modal-title"},[_vm._v("Pin Media")]),_c('button',{ref:"closeModalPin",staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('img',{attrs:{"src":require("../../../../../assets/close.png"),"alt":""}})])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"pin-modal"},[_c('div',{staticClass:"media-image-sec"},[_c('div',{staticClass:"img"},[(
                    _vm.file_extension == 'jpeg' ||
                    _vm.file_extension == 'png' ||
                    _vm.file_extension == 'jpg'
                  )?_c('img',{attrs:{"src":_vm.file_url,"alt":""},on:{"load":_vm.onImageLoad}}):_vm._e(),(
                    _vm.file_extension == 'pdf' ||
                    _vm.file_extension == 'sql' ||
                    _vm.file_extension == 'docx' ||
                    _vm.file_extension == 'doc' ||
                    _vm.file_extension == 'txt' ||
                    _vm.file_extension == 'xlsx' ||
                    _vm.file_extension == 'xls' ||
                    _vm.file_extension == 'ppt' ||
                    _vm.file_extension == 'pptx' ||
                    _vm.file_extension == 'odt' ||
                    _vm.file_extension == 'rtf' ||
                    _vm.file_extension == 'csv'
                  )?_c('img',{attrs:{"src":require("../../../../../assets/images/pinned-document.png"),"alt":"Document Icon"},on:{"load":_vm.onImageLoad}}):_vm._e(),(
                    _vm.file_extension == 'audio/mp3' ||
                    _vm.file_extension == 'mp3' ||
                    _vm.file_extension == 'audio'
                  )?_c('img',{attrs:{"src":require("../../../../../assets/images/mp3.jpg"),"alt":""},on:{"load":_vm.onImageLoad}}):_vm._e(),(
                    _vm.file_extension != 'pdf' &&
                    _vm.file_extension != 'jpeg' &&
                    _vm.file_extension != 'png' &&
                    _vm.file_extension != 'jpg' &&
                    _vm.file_extension != 'mp4' &&
                    _vm.file_extension != 'mp3' &&
                    _vm.file_extension != 'audio/mp3' &&
                    _vm.file_extension != 'sql' &&
                    _vm.file_extension != 'docx' &&
                    _vm.file_extension != 'odt' &&
                    _vm.file_extension != 'rtf' &&
                    _vm.file_extension != 'txt'
                  )?_c('img',{attrs:{"src":require("../../../../../assets/images/pinned-link.png"),"alt":""},on:{"load":_vm.onImageLoad}}):_vm._e(),(_vm.file_extension == 'mp4')?_c('video',{attrs:{"controls":"","src":_vm.file_url},on:{"loadeddata":_vm.onImageLoad}}):_vm._e(),_c('p',{staticClass:"text-center mt-2"},[_vm._v(" "+_vm._s(_vm.file_name)+"."+_vm._s(_vm.file_extension)+" ")])])]),_c('div',{staticClass:"media-info-sec"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Name")]),_c('div',{staticClass:"input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.name.$model),expression:"$v.name.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                      'is-invalid': _vm.validationStatus(_vm.$v.name),
                    },attrs:{"type":"text","id":"name","placeholder":"Enter name","required":""},domProps:{"value":(_vm.$v.name.$model)},on:{"keyup":_vm.errorNull,"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.name.required)?_c('div',{staticClass:"invalid-tooltip"},[_vm._v(" Name is required ")]):_vm._e()])]),(_vm.teamId.chatType == 'team')?_c('div',{staticClass:"mt-2"},[_c('label',[_vm._v("Event")]),_c('v-select',{attrs:{"options":_vm.eventsAll,"label":"eventName","placeholder":_vm.eventPlaceholder},on:{"input":_vm.setSelectedEvent},model:{value:(_vm.selectedEvent),callback:function ($$v) {_vm.selectedEvent=$$v},expression:"selectedEvent"}})],1):_vm._e(),(_vm.teamId.chatType == 'team')?_c('div',{staticClass:"mt-2"},[_c('label',[_vm._v("Game")]),_c('v-select',{attrs:{"options":_vm.gamesAll,"label":"name","placeholder":_vm.gamePlaceholder},model:{value:(_vm.selectedGame),callback:function ($$v) {_vm.selectedGame=$$v},expression:"selectedGame"}})],1):_vm._e(),_c('div',{staticClass:"mt-2"},[_c('label',[_vm._v("Tags")]),_c('vue-tags-input',{attrs:{"tags":_vm.tags,"max-tags":10},on:{"tags-changed":(newTags) => (_vm.tags = newTags)},model:{value:(_vm.tag),callback:function ($$v) {_vm.tag=$$v},expression:"tag"}})],1)])])]),_c('div',{staticClass:"event-btns-wrapper p-3 justify-content-end"},[(this.isDisabledPin == false)?_c('button',{staticClass:"btn btn-primary w-25",attrs:{"type":"button","block":"","disabled":_vm.isLoadingArray[0]},on:{"click":function($event){return _vm.pinMedia(0)}}},[(_vm.isLoadingArray[0])?_c('div',{staticClass:"lds-dual-ring"}):_c('span',[_vm._v("Save")])]):(this.isDisabledPin == true)?_c('button',{staticClass:"btn btn-primary-disabled w-25",attrs:{"type":"button","disabled":_vm.isLoadingArray[0]}},[(_vm.isLoadingArray[0])?_c('div',{staticClass:"lds-dual-ring"}):_c('span',[_vm._v("Save")])]):_vm._e()])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }